import React from "react";

import SEO from "../components/seo";
import { isBrowser } from "../components/lib/utils";
import { useAuth } from "../components/lib-admin/auth";

import Loadable from "react-loadable";
import { CircularProgress } from "@material-ui/core";

const SignInContainer = Loadable({
  loader: () => import("../components/container/signin"),
  loading() {
    return <CircularProgress />;
  },
  delay: 150,
});

const Container = Loadable({
  loader: () => import("../components/container/memo"),
  loading() {
    return <CircularProgress />;
  },
  delay: 150,
});

const IndexPage = () => {
  const auth = useAuth();
  return (
    <React.Fragment>
      <SEO title="Memo" />
      {isBrowser() && auth.isAuth() ? <Container /> : <SignInContainer />}
    </React.Fragment>
  );
};

export default IndexPage;
